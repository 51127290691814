/**
 * 项目管理-项目信息-收藏数
 * luxinwen
 * 2023-03-29
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.nickname" placeholder="请输入微信昵称" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.mobile" placeholder="请输入手机号" clearable />
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content">
        <sp-table :columns="tableColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
        </sp-table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        formData: {
          nickname: '', // 微信昵称
          mobile: '' // 手机号
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '微信昵称',
            key: 'nickname'
          },
          {
            title: '手机号',
            key: 'mobile'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        dataId: ''
      };
    },
    created() {
      this.dataId = this.$route.query.id;
      if (this.dataId) this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          buildingId: this.dataId,
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.project.queryCollectList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>